import {
  reducer,
  setImageVerificationStatusIDLE,
  resetImageVerificationFirstImage,
  resetImageVerificationSecondImage,
  resetImageDetectionBoundaryBox,
  setImageDetectionStatusIDLE,
  resetImageDetectionError,
  resetImageVerificationError,
} from 'src/redux/imageProcessing/imageProcessing.slice'
import { ImageVerificationAction, ImageDetectionAction } from 'src/redux/imageProcessing/imageProcessing.action'

export default {
  reducer,
  ImageVerificationAction,
  ImageDetectionAction,
  setImageVerificationStatusIDLE,
  resetImageVerificationFirstImage,
  resetImageVerificationSecondImage,
  resetImageDetectionBoundaryBox,
  setImageDetectionStatusIDLE,
  resetImageDetectionError,
  resetImageVerificationError,
}
