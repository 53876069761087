import { post } from 'src/utils/request'
import { VERIFICATION, DETECTION } from 'src/constant/api/api-constraints'
import { imageVerificationApiParam, imageDetectionApiParam } from 'src/interfaces/apis'
import storage from 'src/utils/storage'

export const imageVerificationApi = async (body: imageVerificationApiParam) => {
  const token = storage.get('jwtToken')
  return await post({
    url: VERIFICATION,
    body: body,
    header: {
      'Authorization': `Bearer ${token}`,
    },
  })
}

export const imageDetectionApi = async (image: imageDetectionApiParam) => {
  const token = storage.get('jwtToken')
  return await post({
    url: DETECTION,
    body: image,
    header: {
      'Authorization': `Bearer ${token}`,
    },
  })
}
