const color = {
  green001: '#24E4D8',
  green002: '#28C6AC',
  darkerBlue: '#0D3BA5',
  blue001: '#25CDD9',
  blue002: '#3B93DC',
  blue003: '#174CC4',
  blue004: '#113FA6',
  blue005: '#333077',
  blue006: '#1A1755',
  blue007: '#121039',
  blue008: '#211D6D',
  blue009: '#0A0922',
  blue010: '#141240',
  redError: '#E2453B',
  grey001: '#EBEBEB',
  grey002: '#B2B2B2',
  grey003: '#BABABA',
  white: '#FFFFFF',
  black: '#000000',
}

export default color
