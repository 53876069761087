import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { Button, Modal } from 'src/components'

import { convertImgToBase64 } from 'src/utils/image'

import { ImageIcon } from 'src/assets/images'
import { ImageUploaderProps } from 'src/interfaces/components'

import { MODAL_TYPE } from 'src/constant/modal'
import { LIMIT_IMAGE_SIZE } from 'src/constant/image'

import { loading, uploader, modalUploader, btnModalUploader, btnUploader } from 'src/constant/testID/ImageUploader'

import FileUploaderStyled, { ImageUploadBttonStyled } from './styled'

const ImageUploader = ({ id, className, onFinish, isLoading, image, setImage }: ImageUploaderProps) => {
  const { t } = useTranslation()

  const [isShowErrorModal, setIsShowErrorModal] = useState<boolean>(false)

  useEffect(() => {
    if (image && onFinish) onFinish(image)
  }, [image])

  const handleFileChange = (files: FileList | null) => {
    if (files) {
      const isImageCorrectFormat =
        files[0].type.split('/')[1] === 'jpeg' ||
        files[0].type.split('/')[1] === 'jpg' ||
        files[0].type.split('/')[1] === 'png' ||
        files[0].type.split('/')[1] === 'bmp'
      // @ts-ignore
      const isImageNotOverLimitSize = parseInt(files[0].size + '') < LIMIT_IMAGE_SIZE
      if (files[0].type.split('/')[0] === 'image' && isImageCorrectFormat && isImageNotOverLimitSize) {
        convertImgToBase64({ image: files[0], callbackImage: setImage })
        setIsShowErrorModal(false)
      } else {
        setIsShowErrorModal(true)
      }
    }
  }

  const handleCloseModal = () => setIsShowErrorModal(false)

  return (
    <FileUploaderStyled id={id} className={clsx('flex-column justify-content-center align-items-center', className && className)}>
      {isLoading ? (
        <div data-testid={loading}>
          <div className="loading">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div>{t('please_wait_a_moment')}</div>
        </div>
      ) : (
        <>
          <input
            data-testid={uploader}
            id="id"
            className="image-upload-input"
            type="file"
            accept="image/png, image/jpeg, image/jpg, .bmp"
            onChange={(event) => handleFileChange(event.target.files)}
            // @ts-ignore
            onClick={(event) => (event.target.value = '')}
          />
          <div className="upload-image-icon">
            <ImageIcon />
          </div>
          <div>{t('drag_image')}</div>
          <div>{t('click_to_choose')}</div>
        </>
      )}
      <Modal className="img-upload-error-modal" isShow={isShowErrorModal} onClose={handleCloseModal} type={MODAL_TYPE.ERROR}>
        <div className="error-modal-title">{t('unsupported_file')}</div>
        <div className="error-modal-detail flex-column justify-content-center align-items-center">
          {/* <div>{t('please_upload_a_valid_file')}</div> */}
          <div>{t('please_upload_a_valid_file')}</div>
          <div>
            {t('available_image_format')}: <span className="text-bold">{t('jpg,jpeg,png_or_bmp')}</span>
          </div>
          <div>
            {t('maximum_size')}: <span className="text-bold">{t('4_0_mb')}</span>
          </div>
        </div>
        <div className="flex-column justify-content-center align-items-center upload-btn">
          <div className="flex justify-content-center align-items-center image-upload-btn-wrapper">
            <Button text={t('upload_new_image')} icon={<span className="icon-image" />} />
            <input
              data-testid={modalUploader}
              className="image-upload-input"
              type="file"
              accept="image/png, image/jpeg, image/jpg, .bmp"
              onChange={(event) => handleFileChange(event.target.files)}
            />
          </div>
        </div>
      </Modal>
    </FileUploaderStyled>
  )
}

export const ImageUploadButton = ({ id, className, onFinish, isLoading, image, setImage }: ImageUploaderProps) => {
  const { t } = useTranslation()

  const [isShowErrorModal, setIsShowErrorModal] = useState<boolean>(false)

  useEffect(() => {
    if (image && onFinish) onFinish(image)
  }, [image])

  const handleFileChange = (files: FileList | null) => {
    if (files) {
      const isImageCorrectFormat =
        files[0].type.split('/')[1] === 'jpeg' ||
        files[0].type.split('/')[1] === 'jpg' ||
        files[0].type.split('/')[1] === 'png' ||
        files[0].type.split('/')[1] === 'bmp'
      // @ts-ignore
      const isImageNotOverLimitSize = parseInt(files[0].size + '') < LIMIT_IMAGE_SIZE
      if (files[0].type.split('/')[0] === 'image' && isImageCorrectFormat && isImageNotOverLimitSize) {
        convertImgToBase64({ image: files[0], callbackImage: setImage })
        setIsShowErrorModal(false)
      } else {
        setIsShowErrorModal(true)
      }
    }
  }

  const handleCloseModal = () => setIsShowErrorModal(false)

  return (
    <ImageUploadBttonStyled className={clsx('flex-column justify-content-center align-items-center', className && className)}>
      <div className="flex justify-content-center align-items-center image-upload-btn-wrapper">
        <Button text={t('upload_new_image')} icon={<span className="icon-image" />} />
        <input
          data-testid={btnUploader}
          id={id}
          className="image-upload-input"
          type="file"
          accept="image/png, image/jpeg, image/jpg, .bmp"
          onChange={(event) => handleFileChange(event.target.files)}
        />
      </div>
      <Modal className="img-upload-error-modal" isShow={isShowErrorModal} onClose={handleCloseModal} type={MODAL_TYPE.ERROR}>
        <div className="error-modal-title">{t('unsupported_file')}</div>
        <div className="error-modal-detail flex-column justify-content-center align-items-center">
          {/* <div>{t('please_upload_a_valid_file')}</div> */}
          <div>{t('please_upload_a_valid_file')}</div>
          <div>
            {t('available_image_format')}: <span className="text-bold">{t('jpg,jpeg,png_or_bmp')}</span>
          </div>
          <div>
            {t('maximum_size')}: <span className="text-bold">{t('4_0_mb')}</span>
          </div>
        </div>
        <div className="flex-column justify-content-center align-items-center upload-btn">
          <div className="flex justify-content-center align-items-center image-upload-btn-wrapper">
            <Button text={t('upload_new_image')} icon={<span className="icon-image" />} />
            <input
              data-testid={modalUploader}
              className="image-upload-input"
              type="file"
              accept="image/png, image/jpeg, image/jpg, .bmp"
              onChange={(event) => handleFileChange(event.target.files)}
            />
          </div>
        </div>
      </Modal>
    </ImageUploadBttonStyled>
  )
}

export default ImageUploader
