import { createSlice } from '@reduxjs/toolkit'

import { ProfileState } from 'src/interfaces/redux'
import { profileState } from 'src/constant/slice'

import { GetProfileAction } from 'src/redux/profile/profile.action'
import { STATUS_FAILURE, STATUS_PENDING, STATUS_SUCCESS } from 'src/constant/api/status'

const initialState: ProfileState = profileState.initialState

const profileSlice = createSlice({
  name: profileState.name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetProfileAction.pending, (state) => {
        state.status = STATUS_PENDING
      })

      .addCase(GetProfileAction.fulfilled, (state, { payload }) => {
        state.status = STATUS_SUCCESS
        state.email = payload.email
        state.name = payload.name
        state.trial = payload.remaining_usage
      })

      .addCase(GetProfileAction.rejected, (state, { payload }) => {
        state.status = STATUS_FAILURE
        state.error = payload
      })
  },
})

export const reducer = profileSlice.reducer
