import { get } from 'src/utils/request'
import { PROFILE } from 'src/constant/api/api-constraints'
import storage from 'src/utils/storage'

export const getProfileApi = async () => {
  const token = storage.get('jwtToken')
  return await get({
    url: PROFILE,
    header: {
      'Authorization': `Bearer ${token}`,
    },
  })
}
