import { createAsyncThunk } from '@reduxjs/toolkit'
import { getProfileApi } from 'src/apis/profile'
import { profileAction } from 'src/constant/action'
import storage from 'src/utils/storage'

export const GetProfileAction = createAsyncThunk(profileAction, async (undefined, { rejectWithValue }) => {
  const token = storage.get('jwtToken')
  try {
    let response
    if (token && token !== null) response = await getProfileApi()
    return response
  } catch (error) {
    return rejectWithValue(error)
  }
})
