import { createSlice } from '@reduxjs/toolkit'
import Get from 'lodash/get'

import { PageDecisionState } from 'src/interfaces/redux'
import { pageDecisionState } from 'src/constant/slice'

const initialState: PageDecisionState = pageDecisionState.initialState

const pageDecisionSlice = createSlice({
  name: pageDecisionState.name,
  initialState,
  reducers: {
    setAppLoading: (state: PageDecisionState) => {
      state.isLoading = true
    },
    setDisableAppLoading: (state: PageDecisionState) => {
      state.isLoading = false
    },
  },
})

export const reducer = pageDecisionSlice.reducer

const { actions } = pageDecisionSlice

export const { setAppLoading, setDisableAppLoading } = actions
