import { convertImgToBase64Param } from 'src/interfaces/utils'

export const convertImgToBase64 = ({ image, callbackImage }: convertImgToBase64Param) => {
  let reader = new FileReader()
  reader.readAsDataURL(image)
  reader.onload = () => {
    callbackImage(reader.result)
  }
  reader.onerror = (error) => {
    console.log('Error: ', error)
  }
}
