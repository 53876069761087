export const requestURLDetection = '/api/detect'

export const requestURLVerification = '/api/verify'

export const requestSampleDetection = '{\n' + '  "image": {\n' + '    "content": "string",\n' + '    "operations": []\n' + '  }\n' + '}'

export const requestSampleVertification =
  '{\n' +
  '  "image1": {\n' +
  '    "content": "string",\n' +
  '    "operations": []\n' +
  '  },\n' +
  '  "image2": {\n' +
  '    "content": "string",\n' +
  '    "operations": []\n' +
  '  },\n' +
  '  "threshold": 0.95\n' +
  '}'

export const responseSampleDetection = `{"message":"complete","result":{"faces":[{"bbox":[111.11,222.22,333.33,444.44,0.98],"landmarks":[[123.01,123.02],[123.01,123.02],[123.01,123.02],[123.01,123.02],[123.01,123.02]],"pose":"Front"}]}}`

export const responseSampleVerification =
  '{\n' +
  '  "message": "complete",\n' +
  '  "result": {\n' +
  '    "same_person": true,\n' +
  '    "distance": 0,\n' +
  '    "faces": {\n' +
  '      "image1": {\n' +
  '        "bbox": [\n' +
  '          111.11,\n' +
  '          222.22,\n' +
  '          333.33,\n' +
  '          444.44,\n' +
  '          0.98\n' +
  '        ],\n' +
  '        "landmarks": [\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ],\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ],\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ],\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ],\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ]\n' +
  '        ],\n' +
  '        "pose": "Front"\n' +
  '      },\n' +
  '      "image2": {\n' +
  '        "bbox": [\n' +
  '          111.11,\n' +
  '          222.22,\n' +
  '          333.33,\n' +
  '          444.44,\n' +
  '          0.98\n' +
  '        ],\n' +
  '        "landmarks": [\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ],\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ],\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ],\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ],\n' +
  '          [\n' +
  '            123.01,\n' +
  '            123.02\n' +
  '          ]\n' +
  '        ],\n' +
  '        "pose": "Front"\n' +
  '      }\n' +
  '    }\n' +
  '  }\n' +
  '}'

export const FACE_API_DETECTION_TYPE = 'detection'
export const FACE_API_VERIFICATION_TYPE = 'verification'

export const FACE_API_ERROR_OUT_OF_TRIAL = 'out of trial'
export const FACE_API_ERROR_FAIL_TO_PROCESS = 'fail to process'

export const LIMIT_API_QUOTA = 20

export const FACE_API_ERROR_STATUS_CODE = 422
export const FACE_API_ERROR_OUT_OF_TRIAL_CODE = 403
