import { combineReducers } from '@reduxjs/toolkit'
import imageProcessing from './imageProcessing'
import profile from './profile'
import pageDecision from './pageDecision'
import textApi from './textApi'

const rootReducer = combineReducers({
  imageProcessing: imageProcessing.reducer,
  profile: profile.reducer,
  pageDecision: pageDecision.reducer,
  textApi: textApi.reducer,
})

export default rootReducer
