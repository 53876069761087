import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import i18n from 'i18next'
import { I18nextProvider } from 'react-i18next'
import { useSelector } from 'react-redux'
import Get from 'lodash/get'
import AOS from 'aos'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEn from 'src/locale/en'
import translationTh from 'src/locale/th'

import { RootState } from 'src/interfaces/redux'

import { DEFAULT_PAGE_PATH } from 'src/constant/routes'

import useOfflinePopup from 'src/hooks/useOfflinePopup'

import { ApplicationLayout, HomePage, LoadingScreen, Unauthorize, SomethingWentWrong } from 'src/containers'
import { STATUS_FAILURE } from 'src/constant/api/status'
import { UNAUTHORIZED_STATUS } from 'src/constant/profileAPI'

const Router = () => {
  useEffect(() => {
    AOS.init({ once: true })

    i18n.use(LanguageDetector).init({
      lng: !localStorage.getItem('i18nextLng') ? 'EN' : undefined,
      resources: {
        EN: {
          translation: translationEn,
        },
        TH: {
          translation: translationTh,
        },
      },
      fallbackLng: 'EN',
      debug: false,
    })
  }, [])

  const isLoading = useSelector((state: RootState) => Get(state, 'pageDecision.isLoading', true))
  const isAuthPass = useSelector((state: RootState) => Get(state, 'profile.status'))
  const profileErrorStatus = useSelector((state: RootState) => Get(state, 'profile.error.status'))

  const { getOfflinePopup } = useOfflinePopup()

  const getFailAuthPassPage = () => {
    console.log(profileErrorStatus)
    return profileErrorStatus === UNAUTHORIZED_STATUS ? <Unauthorize /> : <SomethingWentWrong />
  }

  const getAllPage = () => {
    let result
    if (isLoading) {
      result = <LoadingScreen />
    } else {
      if (isAuthPass !== STATUS_FAILURE) {
        result = (
          <Routes>
            <Route path={`${DEFAULT_PAGE_PATH}`} element={<HomePage />} />
            <Route path=":tabName" element={<HomePage />} />
            <Route path="*" element={<Navigate to={`${DEFAULT_PAGE_PATH}`} replace />} />
          </Routes>
        )
      } else {
        result = getFailAuthPassPage()
      }
    }
    return (
      <BrowserRouter>
        <ApplicationLayout>{result}</ApplicationLayout>
      </BrowserRouter>
    )
  }

  return (
    <div className="App" id="App">
      <I18nextProvider i18n={i18n}>
        {getOfflinePopup()}
        {getAllPage()}
      </I18nextProvider>
    </div>
  )
}

export default Router
