import React, { useState, useEffect } from 'react'

import useNetwork from './useNetwork'

import { OfflinePopup } from 'src/components'

const useOfflinePopup = () => {
  const { online } = useNetwork()
  const [isShowOfflinePopup, setIsShowOfflinePopup] = useState(false)

  useEffect(() => {
    if (!online) {
      setIsShowOfflinePopup(true)
      const html = document.querySelector('html')
      //@ts-ignore
      html.style.overflowY = 'hidden'
    } else {
      setIsShowOfflinePopup(false)
      const html = document.querySelector('html')
      //@ts-ignore
      html.style.overflowY = 'visible'
    }
  }, [online])

  const getOfflinePopup = () => <OfflinePopup isShow={isShowOfflinePopup} />

  return { getOfflinePopup }
}

export default useOfflinePopup
