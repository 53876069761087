import { createAsyncThunk } from '@reduxjs/toolkit'
import { imageVerificationApi, imageDetectionApi } from 'src/apis/imageProcessing'
import { imageVerificationActionParam } from 'src/interfaces/apis'
import { imageProcessingAction } from 'src/constant/action'
import { FACE_API_ERROR_STATUS_CODE, requestURLDetection, requestURLVerification } from 'src/constant/faceAPI'
import textApi from 'src/redux/textApi'

export const ImageVerificationAction = createAsyncThunk(
  imageProcessingAction.verification,
  async (image: imageVerificationActionParam, { rejectWithValue, dispatch }) => {
    const base64Image1 = image.firstImage.split(',')[1]
    const base64Image2 = image.secondImage.split(',')[1]
    const body = { image1: { content: base64Image1 }, image2: { content: base64Image2 } }
    dispatch(textApi.setTextApiUrl(requestURLVerification))
    dispatch(textApi.setTextApiBody(body))
    try {
      const response = await imageVerificationApi(body)
      dispatch(textApi.setTextApiResponse(response))
      return response.result
    } catch (error: any) {
      if (error.status === FACE_API_ERROR_STATUS_CODE) dispatch(textApi.setTextApiResponse(error.data))
      return rejectWithValue(error)
    }
  }
)

export const ImageDetectionAction = createAsyncThunk(imageProcessingAction.detection, async (image: string, { rejectWithValue, dispatch }) => {
  const base64Image = image.split(',')[1]
  const body = { image: { content: base64Image } }
  dispatch(textApi.setTextApiUrl(requestURLDetection))
  dispatch(textApi.setTextApiBody(body))
  try {
    const response = await imageDetectionApi(body)
    dispatch(textApi.setTextApiResponse(response))
    return response.result
  } catch (error: any) {
    if (error.status === FACE_API_ERROR_STATUS_CODE) dispatch(textApi.setTextApiResponse(error.data))
    return rejectWithValue(error)
  }
})
