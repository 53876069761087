import { useState, useEffect } from 'react'

const useNetwork = () => {
  const getNetworkConnection = () => {
    return (
      // @ts-ignore
      navigator.connection ||
      // @ts-ignore
      navigator.mozConnection ||
      // @ts-ignore
      navigator.webkitConnection ||
      null
    )
  }

  const getNetworkConnectionInfo = () => {
    const connection = getNetworkConnection()

    if (!connection) {
      return {}
    }

    return {
      type: connection.type,
    }
  }

  const [network, setNetwork] = useState(() => {
    return {
      since: undefined,
      online: navigator.onLine,
      ...getNetworkConnectionInfo(),
    }
  })

  useEffect(() => {
    const handleOnline = () => {
      setNetwork((prevState: any) => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }))
    }
    const handleOffline = () => {
      setNetwork((prevState: any) => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }))
    }
    const handleConnectionChange = () => {
      setNetwork((prevState) => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }))
    }
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    const connection = getNetworkConnection()
    connection?.addEventListener('change', handleConnectionChange)
    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
      connection?.removeEventListener('change', handleConnectionChange)
    }
  }, [])

  return network
}

export default useNetwork
