import axios from 'axios'

const callAxiosApi = axios.create({
  baseURL: `${process.env.REACT_APP_BACK_END_URL}/`,
  headers: {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json; charset=UTF-8',
  },
})

callAxiosApi.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error.response)
  }
)

export const get = async ({ url, header }: any) => {
  return callAxiosApi.get(url, { headers: { ...header } }).then((response) => response.data)
}

export const post = async ({ url, body, header }: any) => {
  return callAxiosApi.post(url, body, { headers: { ...header } }).then((response) => response.data)
}
