import { createSlice } from '@reduxjs/toolkit'
import Get from 'lodash/get'

import { ImageProcessingState } from 'src/interfaces/redux'
import { imageProcessingState } from 'src/constant/slice'

import { ImageVerificationAction, ImageDetectionAction } from 'src/redux/imageProcessing/imageProcessing.action'
import { STATUS_FAILURE, STATUS_PENDING, STATUS_SUCCESS, STATUS_IDLE } from 'src/constant/api/status'
import { calculateSimilalityScore } from 'src/utils/faceAPI'

const initialState: ImageProcessingState = imageProcessingState.initialState

const imageProcessingSlice = createSlice({
  name: imageProcessingState.name,
  initialState,
  reducers: {
    setImageVerificationStatusIDLE: (state: ImageProcessingState) => {
      state.imageVerification.status = STATUS_IDLE
    },
    resetImageVerificationFirstImage: (state: ImageProcessingState) => {
      state.imageVerification.firstBoundaryBox = []
    },
    resetImageVerificationSecondImage: (state: ImageProcessingState) => {
      state.imageVerification.secondBoundaryBox = []
    },
    setImageDetectionStatusIDLE: (state: ImageProcessingState) => {
      state.imageDetection.status = STATUS_IDLE
    },
    resetImageDetectionBoundaryBox: (state: ImageProcessingState) => {
      state.imageDetection.boundaryBoxs = []
    },
    resetImageDetectionError: (state: ImageProcessingState) => {
      state.imageDetection.error = undefined
    },
    resetImageVerificationError: (state: ImageProcessingState) => {
      state.imageVerification.error = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ImageDetectionAction.pending, (state) => {
        state.imageDetection.status = STATUS_PENDING
      })

      .addCase(ImageDetectionAction.fulfilled, (state, { payload }) => {
        state.imageDetection.status = STATUS_SUCCESS
        state.imageDetection.error = undefined
        if (payload.faces.length !== 0) {
          const boundaryBoxs = Get(payload, 'faces', []).map((face: any) => {
            return {
              // @ts-ignore
              point1: { x: face.bbox[0], y: face.bbox[1] },
              // @ts-ignore
              point2: { x: face.bbox[2], y: face.bbox[1] },
              // @ts-ignore
              point3: { x: face.bbox[0], y: face.bbox[3] },
              // @ts-ignore
              point4: { x: face.bbox[2], y: face.bbox[3] },
            }
          })
          state.imageDetection.boundaryBoxs = boundaryBoxs
        } else state.imageDetection.boundaryBoxs = []
      })

      .addCase(ImageDetectionAction.rejected, (state, { payload }: any) => {
        state.imageDetection.status = STATUS_FAILURE
        state.imageDetection.error = {
          status: payload.status,
          message: payload.data.message,
        }
      })

      .addCase(ImageVerificationAction.pending, (state) => {
        state.imageVerification.status = STATUS_PENDING
      })

      .addCase(ImageVerificationAction.fulfilled, (state, { payload }) => {
        state.imageVerification.status = STATUS_SUCCESS
        state.imageVerification.error = undefined
        const FirstBoundaryBox = Get(payload, 'faces.image1', [])
        state.imageVerification.firstBoundaryBox = [
          {
            point1: { x: FirstBoundaryBox.bbox[0], y: FirstBoundaryBox.bbox[1] },
            point2: { x: FirstBoundaryBox.bbox[2], y: FirstBoundaryBox.bbox[1] },
            point3: { x: FirstBoundaryBox.bbox[0], y: FirstBoundaryBox.bbox[3] },
            point4: { x: FirstBoundaryBox.bbox[2], y: FirstBoundaryBox.bbox[3] },
          },
        ]

        const SecondBoundaryBox = Get(payload, 'faces.image2', [])
        state.imageVerification.secondBoundaryBox = [
          {
            point1: { x: SecondBoundaryBox.bbox[0], y: SecondBoundaryBox.bbox[1] },
            point2: { x: SecondBoundaryBox.bbox[2], y: SecondBoundaryBox.bbox[1] },
            point3: { x: SecondBoundaryBox.bbox[0], y: SecondBoundaryBox.bbox[3] },
            point4: { x: SecondBoundaryBox.bbox[2], y: SecondBoundaryBox.bbox[3] },
          },
        ]
        state.imageVerification.accuracy = calculateSimilalityScore(payload.distance)
        state.imageVerification.samePerson = payload.same_person
      })

      .addCase(ImageVerificationAction.rejected, (state, { payload }) => {
        state.imageVerification.status = STATUS_FAILURE
        state.imageVerification.error = payload
      })
  },
})

export const reducer = imageProcessingSlice.reducer

const { actions } = imageProcessingSlice
export const {
  setImageVerificationStatusIDLE,
  resetImageVerificationFirstImage,
  resetImageVerificationSecondImage,
  resetImageDetectionBoundaryBox,
  setImageDetectionStatusIDLE,
  resetImageDetectionError,
  resetImageVerificationError,
} = actions
