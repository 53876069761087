const translateEn = {
  'go_beyond_your_vision_with_sertis': '<0>Go beyond your vision</0> with sertis',
  'leverage_computer_vision_technology': 'Leverage computer vision technology to transform your business.',
  'face_detection': 'Face Detection',
  'face_verification': 'Face Verification',
  'the_face_detection_api_can_detect_and_locate_faces':
    'The face detection API can detect and locate faces in an image or video. Use it as the foundation of different face AI applications, such as face recognition, gender and age, classification, and facial expression recognition.',
  'for_more_information': 'For more information\n on state-of-the-art model development',
  'read_our_publication': 'Read our publication',
  'detects_face_key_points': 'Detects face keypoints\n (Eyes, Nose and Mouth)',
  'unlimited_number_of_faces_detected': 'Unlimited number\n of faces detected',
  'head_pose_recognition': 'Head pose recognition\n (Frontal, Left and Right)',
  'lightweight_high_speed_processing': 'Lightweight high speed\n processing',
  'awards': 'Awards',
  'part_of_top_ten_ranking': 'Part of top ten ranking in WIDER FACE challenge (2020)',
  'data_privacy': 'Data Privacy',
  'images_and_personal_identities': 'Images and personal identities are not saved after detection process is complete.',
  'try_demo_now': '<0>Try demo</0> now!',
  'upload_a_photo_and_let_the_face_detection': 'Upload a photo and let the face detection API try to detect all the faces.',
  'trial_limit': 'Trial limit',
  'times': 'Times',
  'restrictions': 'Restrictions',
  'only_images_format_can_be_recognized': 'Only images in JPG, JPEG or PNG format can be recognized.',
  'the_image_size_must_be_less_than_4_mb': 'The image size must be less than 4 MB.',
  'the_image_resolution_must_be':
    'The image resolution must be less than 4,096 x 2,160 px. The face resolution in an image must be greater than 80 x 80 px. It is recommended that the face resolution be greater than 120 x 120 px.',
  'the_face_verification_api_compares_two_images':
    'The face verification API compares two images and verifies if the detected faces are the same person. Images can be captured from a webcam video, passport, ID card, or mugshot. The API can be embedded in digital customer onboarding and other remote verification processes.',
  'crop_and_align_face_from_image': 'Crop and align face from image (ID card, passport, mugshot)',
  'face_comparison_and_verification': '1:1 Face comparison and verification',
  'part_of_top_50_ranking': 'Part of top 50 ranking in international NIST competition\n (2020, 2021)',
  'upload_two_photos': 'Upload two photos and let the Face Verification API compare faces.',
  'submit': 'Submit',
  'uploading_new_images_will_cost_1_trial': '* Uploading new images will cost 1 trial.',
  'all_rights_reserved': '© 2021 Sertis Co.,Ltd. All rights reserved.',
  'contact_us': 'Contact us',
  'tel': 'Tel.',
  'sertis_co_ltd': 'Sertis Co.,Ltd.',
  '597_5_Sukhumvit_Road': '597/5 Sukhumvit Road, Khlong Tan Nuea, Wattana,  Bangkok, Thailand 10110',
  'no_internet_connection': 'No internet connection',
  'please_check_your_internet': 'Please check your internet and try again.',
  'try_again': 'Try again',
  'drag_image': 'Drag image file here or',
  'click_to_choose': 'Click to choose an image from your computer.',
  'please_wait_a_moment': 'Please wait a moment.',
  'show_API_document': 'Show API document',
  'request_URL': 'Request URL',
  'request': 'Request',
  'response': 'Response',
  'upload_new_image': 'Upload new image',
  'verification_result': 'Verification result',
  'same_person': 'Same person',
  'different_person': 'Different person',
  'similarity_score': 'Similarity score',
  'reset': 'Reset',
  'make_sure_there_is_at_least_one_face': 'Please make sure there is at least one face in the image.',
  'invalid_image': 'Invalid image',
  'please_upload_a_valid_image': 'Please upload a valid image.',
  'available_image_format': 'Available image format',
  'jpg,jpeg,png_or_bmp': 'JPG, JPEG, PNG',
  'maximum_size': 'Maximum size',
  '4_0_mb': '4.0 MB',
  'thank_you_for_your_interest': 'Thank you for your interest in Visionlab APIs',
  'you_have_reach_the_maximum_number':
    'You have reached the maximum number of API trials. We hope you are satisfied with the result. Please contact us further for more information or additional demo options.',
  'failed_to_process': 'Failed to process',
  'system_cannot_process': 'System can’t process your image. Please try agian.',
  'ok': 'OK',
  'make_sure_there_are_faces': 'Please make sure there are faces in both images.',
  'unauthorized_access': 'Unauthorized access',
  'use_link_in_email_access': 'Please use the link in your email to access the demo.',
  'face-verification': 'Face Verification',
  'face-detection': 'Face Detection',
  'unsupported_file': 'Unsupported file',
  'please_upload_a_valid_file': 'Please upload a valid file.',
  'we’ll_take_you_there_shortly': 'we’ll take you there shortly',
  'please_wait': 'Please wait, ',
  'something_went_wrong': 'Something went wrong',
  'please_try_again': 'Please try again.',
}

export default translateEn
