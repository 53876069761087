import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TextApiState, bodyRequest, apiResponse } from 'src/interfaces/redux'
import { textApiState } from 'src/constant/slice'

const initialState: TextApiState = textApiState.initialState

const textApiSlice = createSlice({
  name: textApiState.name,
  initialState,
  reducers: {
    setTextApiUrl: (state: TextApiState, { payload }: PayloadAction<string>) => {
      state.url = payload
    },
    setTextApiBody: (state: TextApiState, { payload }: PayloadAction<bodyRequest>) => {
      state.body = payload
    },
    setTextApiResponse: (state: TextApiState, { payload }: PayloadAction<apiResponse>) => {
      state.response = payload
    },
  },
})

export const reducer = textApiSlice.reducer

const { actions } = textApiSlice
export const { setTextApiUrl, setTextApiBody, setTextApiResponse } = actions
