import { STATUS_IDLE } from '../api/status'

export const imageProcessingState = {
  name: 'imageProcessing',
  initialState: {
    imageVerification: {
      firstBoundaryBox: undefined,
      secondBoundaryBox: undefined,
      accuracy: undefined,
      samePerson: undefined,
      status: STATUS_IDLE,
      error: undefined,
    },
    imageDetection: {
      boundaryBoxs: undefined,
      status: STATUS_IDLE,
      error: undefined,
    },
  },
}

export const profileState = {
  name: 'profile',
  initialState: {
    email: '',
    name: '',
    trial: 0,
    status: STATUS_IDLE,
    error: undefined,
  },
}

export const pageDecisionState = {
  name: 'pageDecision',
  initialState: {
    isLoading: true,
  },
}

export const textApiState = {
  name: 'textApi',
  initialState: {
    url: '',
    body: undefined,
    response: undefined,
  },
}
