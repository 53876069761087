import styled from 'styled-components'
import color from 'src/assets/styles/color'
import { MAX_WIDTH_MOBILE } from 'src/constant/screen'

export default styled.div`
  width: 100%;
  height: 428px;
  position: relative;
  background: rgba(23, 76, 196, 0.1);
  border: 1px solid ${color.blue003};
  border-radius: 12px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;

  .image-upload-input {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
  }

  .upload-image-icon {
    margin-bottom: 22px;
  }

  .loading {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    z-index: 1;

    &:after {
      z-index: -1;
      content: '';
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 46px;
      height: 46px;
      margin: 8px;
      border: 6px solid ${color.blue003};
      border-radius: 50%;
      left: 0;
    }
  }

  .loading div {
    display: block;
    position: absolute;
    width: 46px;
    height: 46px;
    margin: 8px;
    border: 6px solid ${color.white};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${color.white} transparent transparent transparent;
  }

  .loading div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .loading div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .loading div:nth-child(3) {
    animation-delay: -0.15s;
  }

  .img-upload-error-modal {
    color: ${color.blue007};

    .error-modal-title {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 24px;
    }

    .error-modal-detail {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 40px;
    }

    .upload-btn {
      position: relative;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}) {
    font-size: 14px;
    padding: 24px 20px;
  }
`

export const ImageUploadBttonStyled = styled.div`
  .image-upload-btn-wrapper {
    position: relative;

    .image-upload-input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  .upload-btn {
    position: relative;
  }

  .img-upload-error-modal {
    color: ${color.blue007};

    .error-modal-title {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 24px;
    }

    .error-modal-detail {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 40px;
    }

    .upload-btn {
      position: relative;
    }
  }
`
