import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals.js'
import { Provider } from 'react-redux'
import store from 'src/store'
import Router from 'src/routes/router'
import 'src/assets/styles/normalize.css'
import 'src/assets/styles/global.css'
import 'aos/dist/aos.css'
import 'src/assets/iconFont/style.css'
import 'highlight.js/styles/github.css'

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
